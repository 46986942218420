import Markdown from 'react-markdown';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';

function Clients (props) {
  const { data: { allMarkdownRemark: { edges: [data] }, site } } = props;
  const { node: { frontmatter: { entry, references } } } = data;
  const hasWindow = typeof window !== 'undefined' && window;

  return (
    <Layout title={site.siteMetadata.title}>
      <SEO title="Auftraggeber von Franziska Schittler" description={`${references.reduce((acc, val) => {
        return `${acc}; ${val.name || ''}`;
      }, '')}`}/>
      <section className="section pb-0">
        <div className={'columns'}>
          <div className={'column is-10 is-offset-1'}>
            <h1 className="title mb-0">Auftraggeber</h1>
          </div>
        </div>
      </section>
      <section className="section pb-0">
        <div className={'column is-10 is-offset-1 pb-0'}>
          <div className={'content is-size-5  has-text-left-mobile'}>
            {entry &&
              <Markdown source={`${entry}`}/>
            }
          </div>
        </div>
      </section>
      <section className="section">
        <div className={'column is-10 is-offset-1'}>
          <div className={'columns is-multiline'}>
            {references.map(ref =>
              <div className={'column is-4'} key={ref.name}>
                <a href={ref.link ? ref.link : hasWindow && window.location.pathname} target={ref.link ? '_blank' : '_self'}>
                  <div className="card card-equal-height">
                    <div className="card-image auto-margin">
                      {ref.logo &&
                    <figure className="image is-128x128 pt-5">
                      <Img fluid={ref.logo.childImageSharp.fluid} alt="" objectFit="cover"/>
                    </figure>
                      }
                    </div>
                    <div className="card-content">
                      <div className="content">
                        <p className={'is-size-5'}>
                          {ref.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Clients;

export const pageQuery = graphql`
    query Clients {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "clients" } } }
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        entry
                        references {
                            link
                            name
                            logo {
                                childImageSharp {
                                    fluid(maxWidth: 512, , quality: 75) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
